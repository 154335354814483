<template>
<div>
    <v-app-bar
        class="white--text"
        color="green"
    >
        <v-app-bar-nav-icon 
            class="white--text"
            @click.stop="isOpen = !isOpen"
        ></v-app-bar-nav-icon>
        <v-app-bar-title>
            Калькулятор прикормки
        </v-app-bar-title>
    </v-app-bar>
</div>
</template>

<script>

export default {
    data() {
        return {
            isOpen: false
        }
    }
}
</script>

<style scoped>

</style>

