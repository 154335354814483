<template>
    <v-app>
        <v-main>
            <navBar/>
            <v-container>
                <inputsArea/>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import navBar from './components/navBar';
import inputsArea from './components/inputsArea'

export default {
    name: 'App',
    components: {
        navBar,
        inputsArea,
    },

    data() {
        return {

        }
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';

</style>