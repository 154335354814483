<template>
    <v-card
        class="pa-3"
        elevation="10"
    >
        <v-select
            label="Количество кукурузы"
            v-model="cornCount"
            :items="cornPercentList"
            @change="changeCornCount"
        ></v-select>
        <v-select
            label="Количество сухаря"
            v-model="crackerCount"
            :items="crackerPercentList"
            @change="changeCrackerCount"
        ></v-select>
        <v-text-field
            label="Количество пакетов"
            v-model="pocketCount"
            type="number"
            @input="calculate"
        ></v-text-field>
        <v-card color="mt-4 pa-2 d-flex flex-row justify-space-between">
            Количество кукурузы:
            <span>
                {{ cornWeight }} кг    
            </span>    
        </v-card>
        <v-card color="mt-4 pa-2 d-flex flex-row justify-space-between">
            Количество сухаря:
            <span>
                {{ craсkerWeight }} кг    
            </span>
        </v-card>
        <v-card color="mt-4 pa-2 d-flex flex-row justify-space-between">
            Общий вес: 
            <span>
                {{ result }} кг
            </span>
        </v-card>
            <v-snackbar 
                :color="snackbar.color" 
                v-model="snackbar.active" 
                :timeout="snackbar.timeout"
            >
                <v-icon>{{ snackbar.icon }}</v-icon>
                {{ snackbar.msg }}
            </v-snackbar>
    </v-card>
</template>

<script>
export default {
    name: "inputsArea",
    data() {
        return {
            cornCount: 70,
            crackerCount: 30,
            pocketCount: 1,
            result: (0.900).toFixed(3),
            cornWeight: (0.630).toFixed(3),
            craсkerWeight: (0.270).toFixed(3),
            cornPercentList:[
                5, 10, 15, 20,
                25, 30, 35, 40,
                45, 50, 55, 60,
                65, 70, 75, 80,
                85, 90, 95
            ],
            crackerPercentList:[
                5, 10, 15, 20,
                25, 30, 35, 40,
                45, 50, 55, 60,
                65, 70, 75, 80,
                85, 90, 95
            ],
            snackbar:{
                active: false,
                timeout: 2000,
                msg: "",
                color: "",
                icon: ""
            }
        }
    },
    methods: {
        calculate() {
            if (this.cornCount || this.crackerCount) {
                if(this.pocketCount > 0){
                    this.cornWeight = (((900 * this.pocketCount) / 100 * this.cornCount) / 1000.0).toFixed(3)
                    this.craсkerWeight = (((900 * this.pocketCount) / 100 * this.crackerCount) / 1000.0).toFixed(3)
                    this.result = (+this.cornWeight + +this.craсkerWeight).toFixed(3);
                } else {
                    this.snackbar = {
                            active: true,
                            timeout: 2000,
                            msg: "Кол.во пакетов меньше 0!",
                            color: "error",
                            icon: "mdi-error"
                    }
                    this.cornWeight = (0).toFixed(3)
                    this.craсkerWeight = (0).toFixed(3)
                    this.result = (0).toFixed(3)
                }
            } else {
                this.snackbar = {
                    active: true,
                    timeout: 2000,
                    msg: "Введите процент!",
                    color: "error",
                    icon: "mdi-alert-circle"
                }
            }
        },
        changeCornCount(){
            this.crackerCount = 100 - this.cornCount
            this.calculate()
        },
        changeCrackerCount(){
            this.cornCount = 100 - this.crackerCount
            this.calculate()
        }
    }
}
</script>

<style>

</style>